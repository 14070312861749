$(document).ready(function() {
  $(".click-1").click(function() {
    $(".step-1").toggle(500);
    $(".step-1-a").toggle(500);
  });
  $(".click-2").click(function() {
    $(".step-2").toggle(500);
    $(".step-2-a").toggle(500);
  });
  $(".od-m").click(function() {
    $(".od-items-m").toggle(500);
  })
  $("#delivery-type-select").change(function() {
    if ($("#delivery-type-select").val() == "Pick up") {
      $(".pickup-stations-delivery").show(500);
      $(".user-addresses-delivery").hide(500);
      // $(".station-checkbox").click(function() {
      //   if ($(".station-checkbox").prop("checked", true)) {
      //     $(".delivery-submit-button").show(500);
      //   } else if ($(".station-checkbox").prop("checked", false)) {
      //     $(".delivery-submit-button").hide(500);
      //   }
      // });
      $(".delivery-submit-button").show(500);
    } else if (
      $("#delivery-type-select").val() == "Delivery to your home/office"
    ) {
      $(".user-addresses-delivery").show(500);
      $(".pickup-stations-delivery").hide(500);
      $(".delivery-submit-button").show(500);
    } else {
      $(".user-addresses-delivery").hide(500);
      $(".pickup-stations-delivery").hide(500);
      $(".delivery-submit-button").hide(500);
    }
    $(".delivery-submit-button").load(" .delivery-submit-button > *");
  });
  $("#pickup-select").click(function() {
    $("#delivery-select").prop("checked", false);
    $(".pickup-stations-delivery").show(500);
    $(".user-addresses-delivery").hide(500);
    $(".delivery-submit-button").show(500);
  });
  $("#delivery-select").click(function() {
    $("#pickup-select").prop("checked", false);
    $(".user-addresses-delivery").show(500);
    $(".pickup-stations-delivery").hide(500);
    $(".delivery-submit-button").show(500);
  });
  // To ensure and address is always selected
  $(".address-checkbox").click(function() {
    $(".address-checkbox").prop("checked", false);
    $(this).prop("checked", true);
  });
  $(".station-checkbox").click(function() {
    $(".station-checkbox").prop("checked", false);
    $(this).prop("checked", true);
  });
  $("#close-delivery-panel").click(function() {
    $(".step-2").hide(500);
    $(".step-2-a").hide(500);
    $("#complete-2").show(300);
    $(".step-3").show(500);
  });
  $(".click-3").click(function() {
    $(".step-3").toggle(500);
  });
  $("#mpesa-p").click(function() {
    $("#bank-p").prop("checked", false);
    $(".bank-deposit").hide(200);
    $(".mpesa-details").show(200);
    $(".last-p").show(200);
  });
  $("#bank-p").click(function() {
    $("#mpesa-p").prop("checked", false);
    $(".mpesa-details").hide(200);
    $(".bank-deposit").show(200);
    $(".last-p").show(200);
  });
  $("#mpesa-mobile").click(function() {
    $("#bank-mobile").prop("checked", false);
    $(".mpesa-details").show(200);
    $(".bank-deposit").hide(200);
    $(".last-p").show(200);
  });
  $("#bank-mobile").click(function() {
    $("#mpesa-mobile").prop("checked", false);
    $(".bank-deposit").show(200);
    $(".mpesa-details").hide(200);
    $(".last-p").show(200);
  });
  $("#card-p").click(function() {
    $("#mpesa-p").prop("checked", false);
    $("#cash-p").prop("checked", false);
    $(".payment-phone").hide(200);
    $(".card-form").show(200);
    $(".payment-submit").hide(200);
  });
  // To ensure a payment method is always selected
    $(".payment-checkbox").click(function() {
      $(".payment-checkbox").prop("checked", false);
      $(this).prop("checked", true);
    });
  $("#listing_onsale").click(function() {
    if ($("#listing_onsale").prop("checked") == true) {
      $(".sale-price").show(200);
    } else {
      $(".sale-price").hide(200);
    }
  });
  $("#listing_onsale_multiple").change(function() {
    if ($("#listing_onsale_multiple").val() == "true") {
      $(".sale-price").show(200);
    } else {
      $(".sale-price").hide(200);
    }
  });
  if ($("#listing_onsale").prop("checked") == true) {
    $(".sale-price").show(200);
  }
  $("#featured_product_onsale").click(function() {
    if ($("#featured_product_onsale").prop("checked") == true) {
      $(".sale-price").show(200);
    } else {
      $(".sale-price").hide(200);
    }
  });
  if ($("#featured_product_onsale").prop("checked") == true) {
    $(".sale-price").show(200);
  }
  $("#sliderbutton").click(function() {
    $("#sliderbutton").hide(200);
    $(".form-hide1").show(200);
  });
  $("#sliderbuttonhide").click(function() {
    $(".form-hide1").hide(200);
    $("#sliderbutton").show(200);
  });
  $("#featuredbutton").click(function() {
    $("#featuredbutton").hide(200);
    $(".form-hide2").show(200);
  });
  $("#featuredbuttonhide").click(function() {
    $(".form-hide2").hide(200);
    $("#featuredbutton").show(200);
  });
  $(".category-checkbox").click(function() {
    $(".category-checkbox")
      .not(this)
      .prop("checked", false);
  });
  $(".admin-filter-button").click(function() {
    $(".admin-filters").toggle(500);
  });
  $(".follow-up-button").click(function() {
    $(".follow-up").toggle(500);
  });
  $("img, .lazyload").lazyload({
    failure_limit: Math.max(jQuery("img").length - 1, 0)
  });
  $(".magnify").click(function() {
    $(".big-im").toggle(200);
    $(".small-im").toggle(200);
  });
  $(".help-b").click(function() {
    $(".floating-buttons").toggle();
  });
  $(".actions").click(function() {
    $(".submit", this).hide();
    $(".loading", this).show();
  });
  // Mannual Order Creation
  if ($("#order_user").val() != "") {
    $(".order-details-steps").show();
  }
  // Mannual Order Edit
  $(".user-dets").click(function(){
    $(".order-details-form").toggle(200);
    $(".v-details").toggle(200);
  })
  // FAQs section
  $(".question").click(function(){
    $(".content-f", this).toggle(100);
  })
  // THIS IS FOR WHEN TWO BUTTONS SIDE BY SIDE SHARE THE SAME CLASS NAMES
  $(".actions-custom").click(function() {
    $(".submit-custom").hide();
    $(".loading-custom").show();
  });
  $(".bulk-actions-button").click(function() {
    $(".bulk-actions-submit-buttons").toggle(300);
    $(".bulk-checkbox").toggle(300);
  });
  $(".view-selected").click(function() {
    $(".selected-listings").toggle(300);
  });
  $(".delete-multiple-listings-button").click(function() {
    $("#delete_mulitple").submit();
  });
  $("#select-all-checkbox").click(function() {
    if ($("#select-all-checkbox").prop("checked") == true) {
      $(".listing-checkbox").prop("checked", true);
    } else {
      $(".listing-checkbox").prop("checked", false);
    }
  });
  $(".analytics-edit-button").click(function() {
    $(".analytics-data").toggle(200);
    $(".data-form").toggle(200);
  });
  $(".cli").click(function() {
    $(".analytics-section").toggle(200);
  });
  $(".app-user-reviews").slick({
    slidesToShow: 1,
    autoplay: true,
    arrows: false,
    dots: true,
    autoplaySpeed: 3000
  });
  $(".slick-admin").slick({
    slidesToShow: 1,
    autoplay: true,
    arrows: false,
    dots: false,
    autoplaySpeed: 3000
  });
  $(".linked-slider").slick({
    slidesToShow: 2,
    autoplay: true,
    arrows: false,
    dots: false,
    autoplaySpeed: 3000
  });
  $("#share-button")
    .popover()
    .click(function() {
      setTimeout(function() {
        $("#share-button").popover("hide");
      }, 1000);
    });
  // PAYMNET INSTRUCTIONS
  $(".details-m").slick({
    slidesToShow: 2,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 725,
        settings: {
          slidesToShow: 1,
          arrows: false
        }
      },
      {
        breakpoint: 576,
        settings: {
          arrows: false,
          slidesToShow: 1
        }
      }
    ]
  });
  $("#custom-phone-numbers").click(function() {
    $(".custom-phone-numbers").toggle(200);
  });
});
