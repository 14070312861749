$(document).ready(function() {
  $("#order_user")
    .autocomplete({
      minLength: 1,
      source: "/users",
      delay: 100,
      focus: function(event, ui) {
        $("#order_user").val(ui.item.first_name + " " + ui.item.last_name);
        return false;
      },
      select: function(event, ui) {
        $(".order-details-steps").show(200); //To show the order form
        $('html,body').animate({
            scrollTop: $(".order-details-steps").offset().top},
            'slow'); // To scroll to the bottom of the form

        // place the user.first_name value into the textfield called 'order_fullname'...
        $("#order_user").val(ui.item.first_name + " " + ui.item.last_name);
        // and place the user email into the hidden textfield called 'link_origin_id'.
        $("#order_link_origin_id").val(ui.item.id);
        $("#order_user_id").val(ui.item.id);

        $("#order_fullname").val(ui.item.first_name + " " + ui.item.last_name);

        $("#order_phone_number").val(ui.item.phone_number);
        // and place the user email into the hidden textfield called 'order_email'.
        $("#order_email").val(ui.item.email);
        $("#order_user").val(ui.item.first_name + " " + ui.item.last_name);
        return false;
      }
    })
    .data("uiAutocomplete")._renderItem = function(ul, item) {
    return (
      $("<li></li>")
        .data("item.autocomplete", item)
        // For now which just want to show the person.given_name in the list.
        .append(
          "<a>" +
            item.first_name +
            " " +
            item.last_name +
            "<br>" +
            " " +
            item.phone_number +
            "</a>"
        )
        .appendTo(ul)
    );
  };
});
